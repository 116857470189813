import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Button, Content, CTABox, Spacer } from 'components';
export const _frontmatter = {
  "title": "About"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Content mdxType="Content">
	<Spacer height={100} responsive={{
        md: 80,
        sm: 60
      }} mdxType="Spacer" />
	<h1>About MadNotes App</h1>
	<p>
		Once upon a time, a brave head developer wanted to make things easier
		for his druids. He couldn't watch them ramble between tabs, lose links
		and depart from their thoughts!
	</p>
	<p>
		Full of worry and thoughtfulness, he could see nothing more than the
		flurry of work, wildly flying hands, streaks of sweat, and tired sighs.
	</p>
	<p>
		He cannot leave his friends in distress, he explored the web browser
		lands, searching for a solution, but none were any good.
	</p>
	<p>
		So, one day he decided to stop this madness and started working on the
		MadNotes app!
	</p>
	<p>True story…</p>
    </Content>
    <Spacer height={60} responsive={{
      md: 40,
      sm: 30
    }} mdxType="Spacer" />
    <CTABox color="banana99" description="Your notepad loading in a new browser tab in under 100ms! See yourself." heading="Boost your efficiency!" mdxType="CTABox">
	<Button arrow="right" color="berry99" to="https://use.madnotes.app" type="plain" mdxType="Button">
		Try Now
	</Button>
	<Button color="berry99" to="/#pricing" type="ghost" gtag={['event', 'click', {
        event_label: 'Pricing'
      }]} mdxType="Button">
		See Pricing
	</Button>
    </CTABox>
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      